(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/widgets/article/views/index.js') >= 0) return;  svs.modules.push('/widgets/article/views/index.js');
(function(svs, Handlebars) {
_svs=window.svs=window.svs||{};
_svs.widget=_svs.widget||{};
_svs.widget.article=_svs.widget.article||{};
_svs.widget.article.templates=_svs.widget.article.templates||{};
svs.widget.article.templates.index = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"widget-default"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"isInverted") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":30},"end":{"line":2,"column":71}}})) != null ? stack1 : "")
    + "\">\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"components-content-simple_article-simple_article"),depth0,{"name":"components-content-simple_article-simple_article","data":data,"indent":"        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "    </div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    return " widget-inverted";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"with").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"currentWidget") : depth0),{"name":"with","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":5,"column":9}}})) != null ? stack1 : "");
},"usePartial":true,"useData":true});
Handlebars.partials['widget-article-index'] = svs.widget.article.templates.index;
})(svs, Handlebars);


 })(window);