(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/widgets/article/assets/javascripts/article.js') >= 0) return;  svs.modules.push('/widgets/article/assets/javascripts/article.js');
"use strict";


(function (svs) {
  'use strict';

  const logger = new svs.core.log.Logger('widget:article');
  svs.widget = svs.widget || {};
  svs.widget.article = svs.widget.article || {};
  svs.widget.article.fetchData = (options, data, callback) => {
    try {
      return svs.content.simple_article.contentLoader.loadArticle(options, (err, article) => {
        if (err) {
          logger.warn("Error loading article widget content: ".concat(err.toString()));
          callback(null);
        } else if (article) {
          callback(null, article);
        } else {
          logger.info("Article widget failed to load article in widget area \"".concat(options.area, ", url: ").concat(options.url, "\""));
          callback(null);
        }
      });
    } catch (e) {
      logger.error("Error loading widget: ".concat(e.toString()));
      callback(e);
    }
  };
})(svs);

 })(window);